import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from 'notistack';
import { Formik, Form } from "formik";

import axiosInstance from '../actions/index';
import { trackEvent } from "../utils/tracking";

import EventTypeForm from "../components/eventTypeForm";
import Booking from '../components/booking';
import BookingHeader from "../components/bookingHeader";
import SaveButton from "../components/saveButton";
import EventTypeSaved from "../components/eventTypeSaved";

const EditEventTypeContainer = ({ userdata, styles, eventTypeID }) => {
    const { t } = useTranslation();
    const [timezone, setTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    const domain = window.location.origin;
    let navigate = useNavigate();

    const [availableSlots, setAvailableSlots] = useState([])
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)
    const [formData, setFormData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [eventTypeSavedModal, setEventTypeSavedModal] = useState(false);
    const [eventTypeSavedUrl, setEventTypeSavedUrl] = useState("");
    const [targetCalendarOptions, setTargetCalendarOptions] = useState({});

    useEffect(() => {
        axiosInstance.get(`/event-type/${eventTypeID}`, { withCredentials: true })
            .then(response => {
                let et = response.data
                let defaultLocation = []
                // right now we are only handling one MeetingLocation
                if (et.meeting_location && et.meeting_location.length > 0) {
                    defaultLocation.push(et.meeting_location[0])
                }

                let recurrence
                if (et.limited_from && et.limited_until) {
                    recurrence = {
                        type: "time_range",
                        start: et.limited_from,
                        end: et.limited_until,
                    }
                }

                setFormData({
                    name: et.name,
                    display_name: et.display_name,
                    description: et.description,
                    duration: et.duration,
                    daily_availability: et.availability.daily_availability,
                    timezone: et.availability.timezone,
                    meeting_location: defaultLocation,
                    recurrence: recurrence,
                    reminders: et.reminders,
                    custom_question: et.custom_question,
                    target_calendar: et.target_calendar,
                })
            })
            .catch(error => console.log(error.message))
    }, [])

    const handleInputChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    let handleSubmit = (values) => {
        setIsSubmitting(true)
        // add eventType uuid
        values = { ...values, uuid: eventTypeID }
        axiosInstance.post('/event-type/' + eventTypeID + '/edit', values, { withCredentials: true })
            .then(() => {
                setIsSubmitting(false)
                axiosInstance.get(`/event-type/${eventTypeID}`, { withCredentials: true })
                    .then((resp) => {
                        const eventType = resp.data
                        trackEvent("edit-booking-page", { booking_page_name: values.name });
                        setEventTypeSavedUrl(`${domain}/${userdata.username}/${eventType.name}`)
                        setEventTypeSavedModal(true);
                    })
                    .catch(error => console.log(error.message))
            })
            .catch(error => {
                setIsSubmitting(false)
                if (error.response.status === 500) {
                    enqueueSnackbar('Internal server error', { variant: 'error' });
                }

                if (error.response.status === 400) {
                    if (error.response.data.message === "duplicated_event_name") {
                        enqueueSnackbar(t('component.new_event_type.error_duplicated_event_name'), { variant: 'warning' });
                    } else {
                        enqueueSnackbar('Bad request', { variant: 'warning' });
                    }
                }
            })
    }

    useEffect(() => {
        // Check if formData.duration is a string to avoid wrong calls to the endpoint
        if (typeof formData.duration === 'string') {
            axiosInstance.post('/event-type/simulate-available-slots', formData, { withCredentials: true })
                .then(response => {
                    setAvailableSlots(response.data);
                })
                .catch(error => console.log(error.message))
        }
    }, [formData.daily_availability, formData.duration, formData.recurrence])

    useEffect(() => {
        axiosInstance.get('/calendars', { withCredentials: true })
            .then(response => {
                setTargetCalendarOptions(response.data);
            })
            .catch(error => console.log(error.message))
    }, [])

    if (!formData.hasOwnProperty('name')) {
        return <div>{t('common.loading')}</div>;
    }

    return (
        <>
            <EventTypeSaved
                open={eventTypeSavedModal}
                setOpen={setEventTypeSavedModal}
                url={eventTypeSavedUrl}
                handleClose={() => setEventTypeSavedModal(false)}
                navigateHome={() => navigate("/home")}
                isPro={userdata.subscription_plan != "free"}
            />
            <Formik initialValues={formData} validateOnBlur={false} validateOnChange={true} onSubmit={(values) => { handleSubmit(values) }}>
                {({ setFieldValue, errors, touched }) => (
                    <Form>
                        <div className="flex flex-col md:grid grid-cols-2 md:mx-5 px-10">
                            <div className="md:flex flex-col col-span-1 w-full items-start">
                                <span className="uppercase tracking-wide text-gray-900 text-lg font-bold mb-2">{t('component.edit_event_type.title')}</span>
                            </div>
                        </div>
                        <div className="flex flex-col md:grid grid-cols-5 gap-4 md:mx-5 p-10">
                            {/* <!--Left Col--> */}
                            <div className="md:flex flex-col col-span-2 w-full items-center">
                                <EventTypeForm
                                    isSubmitting={isSubmitting}
                                    styles={styles}
                                    formData={formData}
                                    onInputChange={handleInputChange}
                                    targetCalendarOptions={targetCalendarOptions}
                                    username={userdata.username}
                                    editMode={true}

                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                />
                            </div>

                            {/* <!--Right Col--> */}
                            <div className="flex flex-col col-span-3 h-fit w-full sticky top-10 space-y-2">
                                <div className="flex flex-row-reverse">
                                    <SaveButton isSaving={isSubmitting} />
                                </div>
                                <div className="hidden md:flex flex-col h-fit w-full shadow-lg rounded items-center pt-10 bg-white">
                                    <span className="inline-flex items-center gap-x-1.5 rounded-full bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                                        <svg className="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                                            <circle cx={3} cy={3} r={3} />
                                        </svg>
                                        {t('component.new_event_type.preview_badge')}
                                    </span>
                                    <div className='md:grid md:grid-cols-7'>
                                        <div name="calendar_header" className="md:col-span-2">
                                            <BookingHeader eventTypeInfo={formData} />
                                        </div>
                                        <div className='md:col-span-5'>
                                            <div className="text-gray-900 py-5">
                                                <Booking
                                                    availableSlots={availableSlots}
                                                    handleSelectTimeSlot={setSelectedTimeSlot}
                                                    timezone={timezone}
                                                    setTimezone={setTimezone}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik >
        </>
    )
}

export default EditEventTypeContainer;

import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { RocketLaunchIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import CopyButton from './copyButton'

export default function EventTypeCreated({ open, setOpen, url, handleClose, navigateHome }) {
    const { t } = useTranslation();
    const [showCodeSnippet, setShowCodeSnippet] = useState(false);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => {
                                            setOpen(false);
                                            setShowCodeSnippet(false);
                                            handleClose();
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                    <RocketLaunchIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        {t('component.event_type_created.title')} 👏👏👏
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {t('component.event_type_created.message1')}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            {t('component.event_type_created.message2')}
                                        </p>
                                    </div>
                                    <div className='inline-flex w-full justify-center mt-5'>
                                        <span className='inline-flex truncate border border-gray-300 rounded-l-md items-center px-2'>{url}</span>
                                        <CopyButton url={url} />
                                    </div>
                                </div>
                                <div className="md:grid md:grid-cols-2 m-12">
                                    <div className='md:col-span-1'>
                                        <button
                                            type="button"
                                            className="inline-block justify-center rounded-md bg-white px-3 py-2 border border-gray-300 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                            onClick={() => {
                                                setOpen(false)
                                                navigateHome()
                                            }}
                                        >
                                            {t('component.event_type_created.back_to_dashboard')}
                                        </button>
                                    </div>
                                    <div className='md:col-span-1'>
                                        <a
                                            href={url}
                                            target="_blank"
                                            className="inline-block justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-full"
                                        >
                                            {t('component.event_type_created.view_booking_page')}
                                        </a>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}

const Divider = ({ title }) => {
    return (
        <div className="relative mt-8 mb-2">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-500">{title}</span>
            </div>
        </div>
    )
}
import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

const DurationSelect = ({ name, styles, defaultDuration, onInputChange = () => { } }) => {
    const [duration, setDuration] = useState("15m");
    const [customUnit, setCustomUnit] = useState("minutes");
    const [showCustom, setShowCustom] = useState(false);
    const [customDuration, setCustomDuration] = useState("");

    const { t } = useTranslation();

    // workaround so it shows default 15 minutes when creating an event_type
    // and the custom duration when editing an event_type
    if (defaultDuration === duration) {
        defaultDuration = null
    }

    const handleDurationChange = e => {
        const newDuration = e.value;
        setShowCustom(newDuration === "custom");
        setDuration(newDuration);
    };

    const handleCustomUnitChange = e => {
        setCustomUnit(e.value);
    };

    const handleCustomDurationChange = e => {
        setDuration("custom")
        setCustomDuration(e.target.value);
    };

    const getDurationInMinutes = () => {
        if (duration === "custom") {
            const durationInMinutes = customUnit === "minutes" ? customDuration : customDuration * 60;
            return durationInMinutes.toString() + "m";
        }

        return duration;
    };

    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        // if we are editing the eventType we are setting custom option and asigning the defaultDuration as custom
        if (defaultDuration) {
            setDuration("custom")
            setCustomDuration(defaultDuration);
            setShowCustom(true);
        }
    }, []);

    useEffect(() => {
        const durationInMinutes = getDurationInMinutes();
        setFieldValue("duration", durationInMinutes);
        onInputChange("duration", durationInMinutes);
    }, [duration, customDuration, customUnit]);

    const timeOptions = [
        { value: '15m', label: t('component.duration_select.15minutes') },
        { value: '30m', label: t('component.duration_select.30minutes') },
        { value: '45m', label: t('component.duration_select.45minutes') },
        { value: '60m', label: t('component.duration_select.1hour') },
        { value: 'custom', label: t('component.duration_select.custom') },
    ]

    const unitOptions = [
        { value: 'minutes', label: t('component.duration_select.minutes') },
        { value: 'hours', label: t('component.duration_select.hours') },
    ]

    return (
        <div className="flex flex-wrap">
            <div className="w-full">
                <label htmlFor={`${name}-duration`} className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{t('component.duration_select.duration')}</label>
                <Select
                    id={`${name}-duration`}
                    name={name}
                    onChange={handleDurationChange}
                    options={timeOptions}
                    defaultValue={defaultDuration ? timeOptions[4] : timeOptions[0]}
                    styles={styles.customSelectStyles}
                />
            </div>
            {showCustom && (
                <>
                    <div className="w-full md:w-1/2 mb-6 md:pr-2 md:mb-0">
                        <label htmlFor={`${name}-custom-amount`} className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{t('component.duration_select.amount')}</label>
                        <input
                            id={`${name}-custom-amount`}
                            type="number"
                            value={customDuration}
                            onChange={handleCustomDurationChange}
                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        />
                    </div>
                    <div className="w-full md:w-1/2 mb-6 md:pl-2 md:mb-0">
                        <label htmlFor={`${name}-custom-unit`} className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{t('component.duration_select.unit')}</label>
                        <Select
                            id={`${name}-custom-unit`}
                            onChange={handleCustomUnitChange}
                            options={unitOptions}
                            defaultValue={unitOptions[0]}
                            styles={styles.customSelectStyles}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default DurationSelect;
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { trackEvent } from "../utils/tracking";
import WorkInProgressOverlay from "./workInProgressOverlay";
import InfoOverlay from "./infoOverlay";
import axiosInstance from '../actions/index';

const MeetingLocation = ({ field, form, styles, defaultLocation }) => {
    const [showValueInput, setShowValueInput] = useState(false);
    const [meetingLocationKey, setMeetingLocationKey] = useState("none");
    const [meetingLocationValue, setMeetingLocationValue] = useState("");
    const [openWorkInProgressOverlay, setOpenWorkInProgressOverlay] = useState(false)
    const [zoomConnected, setZoomConnected] = useState(false)
    const [openInfoOverlay, setOpenInfoOverlay] = useState(false)
    const [infoOverlayTitle, setInfoOverlayTitle] = useState("")
    const [infoOverlayMessage, setInfoOverlayMessage] = useState("")

    const { t } = useTranslation();

    useEffect(() => {
        if (defaultLocation && defaultLocation.length > 0) {
            setMeetingLocationKey(defaultLocation[0].key);
            setMeetingLocationValue(defaultLocation[0].value);
            setShowValueInput(defaultLocation[0].key === "in_person");
        }
    }, [])

    useEffect(() => {
        axiosInstance.get('/home', { withCredentials: true })
            .then(response => {
                let { data } = response
                setZoomConnected(data.zoom_connected);
            })
            .catch(error => {
                console.log(error)
            })
    }, [zoomConnected]);

    // set this value to formik value whenever the meeting_location key or value changes
    useEffect(() => {
        if (meetingLocationKey == "" || meetingLocationKey == "none") {
            form.setFieldValue(field.name, [])
            return
        }

        if (meetingLocationKey != "in_person") {
            setMeetingLocationValue("")
        }

        form.setFieldValue(field.name, [{ "key": meetingLocationKey, "value": meetingLocationValue }])
    }, [meetingLocationKey, meetingLocationValue])

    const handleMeetLocationKeyChange = e => {
        const newKey = e.value;
        setShowValueInput(newKey === "in_person");
        if (newKey === "zoom" && !zoomConnected) {
            setInfoOverlayTitle(t('component.meeting_location.zoom_info_overlay_title'))
            setInfoOverlayMessage(t('component.meeting_location.zoom_info_overlay_message'))
            setOpenInfoOverlay(true);
            return
        }
        if (newKey === "teams") {
            setOpenWorkInProgressOverlay(true);
            trackEvent("work-in-progress-feature", { feature_name: newKey });
            return
        }
        setMeetingLocationKey(e.value);
    };

    const meetingLocationOptions = [
        { value: 'none', label: t('component.meeting_location.none') },
        { value: 'google_meets', label: t('component.meeting_location.google_meets') },
        { value: 'in_person', label: t('component.meeting_location.in_person') },
        { value: 'zoom', label: t('component.meeting_location.zoom') },
        { value: 'teams', label: t('component.meeting_location.teams') },
    ]

    return (
        <div className="flex flex-wrap">
            <div className="w-full">
                <label
                    htmlFor="meeting-location-key"
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    {t('component.meeting_location.title')}
                </label>
                <Select
                    id="meeting-location-key"
                    name="meeting-location-key"
                    value={meetingLocationOptions.find(option => option.value === meetingLocationKey)}
                    onChange={handleMeetLocationKeyChange}
                    options={meetingLocationOptions}
                    styles={styles.customSelectStyles}
                    defaultValue={defaultLocation ? meetingLocationOptions.filter(option => option.value === defaultLocation.key) : meetingLocationOptions[0]}
                />
            </div>
            {showValueInput && (
                <div className="w-full mb-6 py-2 md:mb-0">
                    <label htmlFor="meeting-location-value" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{t('component.meeting_location.address')}</label>
                    <input
                        id="meeting-location-value"
                        onChange={(e) => setMeetingLocationValue(e.target.value)}
                        defaultValue={defaultLocation && defaultLocation.length > 0 ? defaultLocation[0].value : ""}
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                </div>
            )}
            <WorkInProgressOverlay open={openWorkInProgressOverlay} setOpen={setOpenWorkInProgressOverlay} />
            <InfoOverlay open={openInfoOverlay} setOpen={setOpenInfoOverlay} title={infoOverlayTitle} message={infoOverlayMessage} />
        </div>
    );
};

export default MeetingLocation;


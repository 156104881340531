import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { StarIcon } from '@heroicons/react/24/solid'

import { trackEvent } from "../utils/tracking";
import CheckoutForm from './stripeCheckout';
import axiosInstance from '../actions/index';


const TargetCalendar = ({ field, form, defaultTargetCalendar, targetCalendarOptions }) => {
    const [subscriptionPlan, setSubscriptionPlan] = useState("");
    const [showPro, setShowPro] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        axiosInstance.get('/home', { withCredentials: true })
            .then(response => {
                let { data } = response
                setSubscriptionPlan(data.subscription_plan);
            })
            .catch(error => {
                console.log(error)
            })
    }, [showPro]);

    const handleTargetCalendarOpen = () => {
        if (subscriptionPlan == "free") {
            trackEvent("show_pro_checkout", { feature: "target_calendar" });
            setShowPro(true);
            return
        }
    }

    const handleTargetCalendarChange = (e) => {
        if (subscriptionPlan == "free") {
            trackEvent("show_pro_checkout", { feature: "target_calendar" });
            setShowPro(true);
            return
        }

        form.setFieldValue(field.name, e.value);
    }

    let options = [];
    if (targetCalendarOptions && targetCalendarOptions.length > 0) {
        options = targetCalendarOptions.map(item => {
            return {
                label: item.Name,
                value: item.ID
            };
        });
    }

    // this is needed so that the default value loads correctly
    if (options.length == 0) {
        return <></>
    }

    return (
        <div className="flex flex-wrap">
            <CheckoutForm open={showPro} setOpen={setShowPro} feature="reminders" />
            <span className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 w-full"> {t('component.target_calendar.title')} </span>
            <span className=" text-gray-500 text-[11px] w-full justify-start mb-2"> {t('component.target_calendar.description')} </span>
            <div className="flex justify-end w-full">
                <StarIcon className="absolute h-2 w-2 z-10 text-indigo-600" />
            </div>
            <Select
                id="target_calendar"
                options={options}
                onMenuOpen={handleTargetCalendarOpen}
                onChange={handleTargetCalendarChange}
                defaultValue={options.find(option => option.value === defaultTargetCalendar)}
                className="w-full"
            />

        </div>
    )
}

export default TargetCalendar
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next'
import Select from 'react-select'

const Recurrence = ({ field, form, defaultRecurrence, onInputChange = () => { } }) => {
    const [showRange, setShowRange] = useState(false);
    const [recurrence, setRecurrence] = useState({
        type: 'weekly',
        start: '',
        end: '',
    });

    useEffect(() => {
        if (defaultRecurrence && defaultRecurrence.start) {
            setRecurrence({
                type: defaultRecurrence.type,
                start: formatToYYYYMMDD(defaultRecurrence.start),
                end: formatToYYYYMMDD(defaultRecurrence.end),
            })
            setShowRange(true)
        }
    }, []);

    const { t } = useTranslation();

    const recurrenceOptions = [
        { value: "weekly", label: t('component.recurrence.weekly') },
        { value: "time_range", label: t('component.recurrence.time_range') }
    ]


    const handleRecurrenceChange = e => {
        if (e.value == "time_range") {
            setRecurrence((prevRecurrence) => ({
                ...prevRecurrence,
                type: "time_range",
            }));
            setShowRange(true);
        } else {
            setRecurrence((prevRecurrence) => ({
                ...prevRecurrence,
                type: "weekly",
                start: '',
                end: '',
            }));
            setShowRange(false);
        }
    };

    const handleRecurrenceRangeChange = (event) => {
        const { id, value } = event.target;
        setRecurrence((prevRecurrence) => ({
            ...prevRecurrence,
            [id]: value,
        }));
    };

    useEffect(() => {
        form.setFieldValue(field.name, recurrence)
        onInputChange(field.name, recurrence)
    }, [recurrence]);



    return (
        <div className="flex flex-wrap">
            <span className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 w-full">{t('component.recurrence.title')}</span>
            <Select
                id="recurrence"
                options={recurrenceOptions}
                value={recurrenceOptions.find(option => option.value === recurrence.type)}
                onChange={handleRecurrenceChange}
                className="w-full"
            />

            {showRange &&
                <div className="flex flex-wrap mt-2 w-full">
                    <div className="w-full md:w-1/2 mb-6 md:pr-1 md:mb-0">
                        <label htmlFor="start" className="block tracking-wide text-gray-700 text-xs font-bold mb-2">{t('component.custom_availability.from')}</label>
                        <input
                            id="start"
                            type="date"
                            value={recurrence.start}
                            onChange={handleRecurrenceRangeChange}
                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        />
                    </div>
                    <div className="w-full md:w-1/2 mb-6 md:pl-1 md:mb-0">
                        <label htmlFor="end" className="block tracking-wide text-gray-700 text-xs font-bold mb-2">{t('component.custom_availability.until')}</label>
                        <input
                            id="end"
                            type="date"
                            value={recurrence.end}
                            onChange={handleRecurrenceRangeChange}
                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default Recurrence;

const formatToYYYYMMDD = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
}

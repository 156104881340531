import { useTranslation } from 'react-i18next'

let ConnectGoogleCalendar = ({ children, ...props }) => {

  const { t, i18n } = useTranslation();

  /*
   * Create form to request access token from Google's OAuth 2.0 server.
   */
  const OauthSignIn = () => {

    // Google's OAuth 2.0 endpoint for requesting an access token
    var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    var form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    var params = {
      'client_id': process.env.REACT_APP_GOOGLE_CLIENT_ID,
      'redirect_uri': process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT,
      'response_type': 'code',
      'scope': 'openid email profile https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.calendarlist.readonly',
      'include_granted_scopes': 'true',
      'access_type': 'offline',
      'prompt': 'select_account',
      'state': i18n.language,
    };

    // Add form parameters as hidden input values.
    for (var p in params) {
      var input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  }

  return (
    <div className="flex">
      <button onClick={OauthSignIn} >
        {children}
      </button>
    </div>
  )
}

export default ConnectGoogleCalendar;
import React, { useEffect, useState } from 'react'

const InputWithAddOn = ({ addon, defaultInput, onInputChange = () => { } }) => {
    const [input, setInput] = useState('')

    useEffect(() => {
        setInput(defaultInput)
    }, [defaultInput])

    return (
        <div className="grid grid-cols-5 mt-2 rounded-md shadow-sm">
            <span className="col-span-2 flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 bg-gray-200 sm:text-sm truncate">
                {addon}
            </span>
            <input
                onChange={(e) => {
                    setInput(e.target.value)
                    onInputChange(e.target.value)
                }}
                value={input}
                className="col-span-3 block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 overflow-x-scroll"
            />
        </div>
    )
}

export default InputWithAddOn
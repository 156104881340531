import { useState, useEffect } from 'react';

import axiosInstance from '../actions/index';
import Booking from '../components/booking';

const BookingContainer = ({ username, calendar_id, handleSelectTimeSlot, timezone, setTimezone }) => {


  const [availableSlots, setAvailableSlots] = useState([])
  const [isLoadingAvailableSlots, setIsLoadingAvailableSlots] = useState(false)

  useEffect(() => {
    setIsLoadingAvailableSlots(true);
    fetchAvailableSlots(username, calendar_id, timezone)
      .then(availableSlots => {
        if (availableSlots) {
          setAvailableSlots(availableSlots)
        }
      }).finally(() => {
        setIsLoadingAvailableSlots(false); // Set isLoading to false when request is complete
      }
      )
  }, [timezone]);

  return (
    <Booking
      availableSlots={availableSlots}
      isLoadingAvailableSlots={isLoadingAvailableSlots}
      handleSelectTimeSlot={handleSelectTimeSlot}
      timezone={timezone}
      setTimezone={setTimezone}
    />
  )
}

export default BookingContainer;


let fetchAvailableSlots = async (username, calendar_id, timezone) => {
  const resp = await axiosInstance.get('/available-slots',
    {
      params: {
        username: username,
        calendar_id: calendar_id,
        tz: timezone,
      }
    })

  return resp.data;
};

import { useState } from "react";
import { Formik, Field, Form } from "formik";
import { useTranslation } from 'react-i18next'
import axiosInstance from '../actions/index';
import Alert from './alert';

let Username = ({ styles, handleUpdateUsername, action }) => {
    const [showError, setShowError] = useState(null);
    const { t } = useTranslation();

    let storeUsername = (values) => {
        let { username } = values
        axiosInstance.post('/account/edit', { "username": username }, { withCredentials: true })
            .then(resp => handleUpdateUsername(resp.data.username))
            .catch(error => setShowError(error.response.data))
    }
    return (
        <div className="mx-4">
            {showError && <Alert color="red" type="error" message={showError} />}
            <div className="flex justify-center">
                <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
                    <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">{t(`component.username.${action}.title`)}</h5>
                    <Formik initialValues={{ username: '' }} onSubmit={(values) => { storeUsername(values) }}>
                        <Form className="">
                            <div>
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                    {t('component.username.subtitle')}
                                </label>
                                <div className="mt-2 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                        {t('component.username.username_field_label')}
                                    </span>
                                    <Field
                                        id="username"
                                        type="text"
                                        name="username"
                                        label="Username"
                                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="username"
                                    />
                                </div>
                            </div>
                            <button type="submit" className={styles.buttonPrimary}>{t(`component.username.${action}.primary_button`)}</button>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>

    )
}

export default Username;
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next'
import { BellAlertIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { StarIcon } from '@heroicons/react/24/solid'

import { trackEvent } from "../utils/tracking";
import Dropdown from './dropdown';
import CheckoutForm from './stripeCheckout';
import axiosInstance from '../actions/index';

const Reminders = ({ field, form, defaultReminders, onInputChange = () => { } }) => {
    const [reminders, setReminders] = useState([]);
    const [showPro, setShowPro] = useState(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState("");

    // loads defaultReminders used to edit reminders
    useEffect(() => {
        if (defaultReminders) {
            const preloadedReminders = defaultReminders.map(reminder_value => {
                return { id: reminders.length, value: reminder_value, unit: 'minutes' }
            })
            setReminders(preloadedReminders);
        }
    }, [])

    useEffect(() => {
        const remindersInMinutes = reminders.map(reminder =>
            reminder.unit === "minutes" ? reminder.value : reminder.value * 60
        );

        form.setFieldValue(field.name, remindersInMinutes)
        onInputChange(field.name, remindersInMinutes)
    }, [reminders]);

    useEffect(() => {
        axiosInstance.get('/home', { withCredentials: true })
            .then(response => {
                let { data } = response
                setSubscriptionPlan(data.subscription_plan);
            })
            .catch(error => {
                console.log(error)
            })
    }, [showPro]);

    const { t } = useTranslation();

    const remindersUnitOptions = [
        { value: "minutes", label: t('component.reminders.minutes') },
        { value: "hours", label: t('component.reminders.hours') },
    ]

    const handleRemindersUnitChange = (idx, option) => {
        // Use map to create a new array with the updated element
        const updatedReminders = reminders.map(reminder =>
            reminder.id === idx ? { ...reminder, unit: option.value } : reminder
        );

        // Update the state with the new array
        setReminders(updatedReminders);
    };

    const handleRemindersValueChange = (idx, value) => {
        // Use map to create a new array with the updated element
        const updatedReminders = reminders.map(reminder =>
            reminder.id === idx ? { ...reminder, value: value } : reminder
        );

        // Update the state with the new array
        setReminders(updatedReminders);
    };

    const handleAddReminder = () => {
        if (subscriptionPlan == "free") {
            trackEvent("show_pro_checkout", { feature: "reminders" });
            setShowPro(true);
            return
        }

        setReminders([...reminders, { id: reminders.length, value: 30, unit: 'minutes' }])
    }

    const handleRemoveReminder = (idx) => {
        const values = [...reminders];
        values.splice(idx, 1);
        setReminders(values);
    }

    return (
        <div className="flex flex-wrap space-y-4">
            <CheckoutForm open={showPro} setOpen={setShowPro} feature="reminders" />
            <span className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 w-full">{t('component.reminders.title')}</span>
            <div className="grid grid-cols-3 gap-2 w-full">
                <div className='flex col-span-3 sm:col-span-2 justify-center sm:justify-start items-center space-x-2'>
                    <span className="text-sm">{t('component.reminders.email_notifications')}</span>
                </div>
                <div className='flex col-span-3 justify-center sm:col-span-1 sm:justify-end'>
                    <button
                        type="button"
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={handleAddReminder}>
                        <div className="flex justify-end w-full">
                            <StarIcon className="absolute h-2 w-2 z-10 text-indigo-600" />
                        </div>
                        {t('component.reminders.add')}
                    </button>
                </div>
            </div>

            {reminders.map((r, idx) => (
                <div className='grid grid-cols-3 gap-2 w-full my-1' key={`remainder_${idx}`}>
                    <div className='col-span-2 flex justify-start space-x-2'>
                        <BellAlertIcon className="h-6 w-6 self-center" />
                        <input
                            id={idx}
                            defaultValue={r.value}
                            className="h-9 w-16 rounded border-gray-300 text-sm"
                            onChange={(e => handleRemindersValueChange(idx, parseInt(e.target.value, 10)))}
                        />
                        <Dropdown
                            id={`unit_${idx}`}
                            options={remindersUnitOptions}
                            defaultValue={remindersUnitOptions.find(option => option.value === r.unit)}
                            onChange={option => handleRemindersUnitChange(idx, option)}
                        />
                    </div>
                    <div className='col-span-1 flex justify-end align-middle'>
                        <button type="button" disabled={reminders.length === 0} onClick={() => handleRemoveReminder(idx)}>
                            <XMarkIcon className="h-5 w-5" />
                        </button>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Reminders;

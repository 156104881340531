import React, { useState, useEffect } from "react";
import axiosInstance from '../actions/index';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'


const BookingListPage = () => {
    const [eventType, setEventType] = useState([]);

    const domain = window.location.origin;
    const { username } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        axiosInstance.get(`/account/${username}/booking-page`, { withCredentials: true })
            .then(response => {
                setEventType(response.data.event_type_list);
            })
            .catch(error => console.log(error.message))
    }, [])

    return (
        <div className="flex flex-col h-screen bg-gray-100 ">
            <div className="flex flex-col items-center mx-5 md:mx-20 mt-20 pb-10 space-y-4 bg-white rounded-md">
                <span className="space-y-4 mt-10 md:mt-20 max-w-sm mx-5 text-sm text-gray-500">{t('component.booking_list_page.title')}</span>
                <ul className="space-y-4 my-10 mx-2 md:mx-5">
                    {eventType.map((et, idx) => (
                        <li key={idx} className="bg-white border border-gray-200 rounded-md group shadow-md hover:shadow-xl hover:bg-gray-50">
                            <a className="flex space-x-1" href={domain + "/" + username + "/" + et.name} target="_blank">
                                <div className="flex flex-col items-center px-4 py-4 md:flex-row sm:px-6">
                                    <div className="flex items-center flex-1 w-full min-w-0">
                                        <div className="block w-full truncate mx-4">
                                            <div className="flex items-center truncate">
                                                <p className="text-base font-medium leading-5 truncate text-blue-800">{et.display_name}</p>
                                            </div>
                                            <div className="my-2">
                                                <p className="text-sm text-gray-400 text-wrap text-left">{et.description}</p>
                                            </div>
                                            <div className="flex mt-2 space-x-4">
                                                <div className="flex items-center text-sm leading-5 text-gray-500 cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400">
                                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd"></path>
                                                    </svg>
                                                    <span className="truncate">{et.duration} {t('common.min')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex justify-center bg-gray-50 rounded-b-md mx-5 md:mx-20 p-2 shadow-lg text-gray-800">
                <a href="https://www.cronoscal.com">Powered by Cronos</a>
            </div>
        </div >
    )
}

export default BookingListPage;
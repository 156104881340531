import { Transition } from '@headlessui/react';
import { InboxIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useNavigate } from "react-router-dom";

const ActionableNotification = ({ message, actionableURL, actionButton, type, show, setShow }) => {
    const navigate = useNavigate();

    const getIcon = () => {
        switch (type) {
            case 'error':
                return <ExclamationCircleIcon aria-hidden="true" className="h-12 w-12 text-red-400" />;
            case 'warning':
                return <ExclamationTriangleIcon aria-hidden="true" className="h-12 w-12 text-yellow-400" />;
            default:
                return <InboxIcon aria-hidden="true" className="h-12 w-12 text-gray-400" />;
        }
    };

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={show}
                        enter="transition ease-out duration-300"
                        enterFrom="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
                        enterTo="opacity-100 translate-y-0 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100 translate-y-0 sm:translate-x-0"
                        leaveTo="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {getIcon()}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">{type.charAt(0).toUpperCase() + type.slice(1)}</p>
                                        <p className="mt-1 text-sm text-gray-500">
                                            {message}
                                        </p>
                                        <div className="mt-3 flex space-x-7">
                                            <button
                                                type="button"
                                                onClick={() => navigate(actionableURL)}
                                                className="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                {actionButton}
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setShow(false);
                                                }}
                                                className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Dismiss
                                            </button>
                                        </div>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setShow(false);
                                            }}
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
};

export default ActionableNotification;
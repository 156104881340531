import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import axiosInstance from '../actions/index';
import CheckoutForm from '../components/stripeCheckout';

let ProPlan = () => {
    const [userdata, setUserData] = useState("");
    const [showPro, setShowPro] = useState(true);

    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const location = useLocation();

    const onClose = () => {
        navigate('/home');
    }


    useEffect(() => {
        axiosInstance.get('/home', { withCredentials: true })
            .then(response => {
                let { data } = response
                setUserData(data);

                if (data.language && data.language != i18n.language) {
                    i18n.changeLanguage(data.language);
                }
            })
            .catch(() => {
                return navigate("/login?redirectTo=" + location.pathname)
            })
    }, [])

    if (!userdata) {
        return <div>{t('common.loading')}</div>
    }


    return <CheckoutForm
        open={showPro}
        setOpen={setShowPro}
        onClose={onClose}
        feature="reminders"
    />;
}



export default ProPlan;
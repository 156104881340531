import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import UserMenu from './userMenu';
import CheckoutForm from './stripeCheckout';
import axiosInstance from '../actions/index';
import { trackEvent } from "../utils/tracking";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = ({ fullname, avatar }) => {
  const [showPro, setShowPro] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("");

  const { t } = useTranslation();
  const location = useLocation();

  const navigation = [
    { name: t('component.header.dashboard'), href: '/home', current: location.pathname == '/home' ? true : false },
  ]

  useEffect(() => {
    axiosInstance.get('/home', { withCredentials: true })
      .then(response => {
        let { data } = response
        setSubscriptionPlan(data.subscription_plan);
      })
      .catch(error => {
        console.log(error)
      })
  }, [showPro]);

  const handleTryPro = () => {
    trackEvent("show_pro_checkout", { feature: "header" });
    setShowPro(true);
  }

  return (
    <Disclosure as="nav" className="border-b border-gray-200 bg-white">
      <CheckoutForm open={showPro} setOpen={setShowPro} feature="reminders" />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="flex flex-shrink-0 items-center sm:mr-10">
              <Link
                to="/home"
                className="toggleColour text-black no-underline hover:no-underline font-bold text-xl">
                CRONOS
              </Link>
            </div>
            <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? 'border-indigo-500 text-gray-900'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          <div className="flex justify-between space-x-2 py-2">
            {subscriptionPlan === "free" && <button
              type="button"
              className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              onClick={handleTryPro}> {t('component.header.try_pro')} </button>
            }
            <UserMenu fullname={fullname} avatar={avatar} />
          </div>
        </div>
      </div>
    </Disclosure >
  )
}

export default Header;
import { useState, useEffect } from "react";
import axiosInstance from '../actions/index';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import 'tw-elements';
import Header from "../components/header";
import CreateEventTypeContainer from "../containers/createEventTypeContainer";
import HelpScoutTagWrapper from '../utils/HelpScoutTagWrapper';

let CreateEventType = ({ styles }) => {
    const [userdata, setUserData] = useState("");
    const [userHasAccess, setUserHasAccess] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [timezone, setTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get('/home', { withCredentials: true })
            .then(response => {
                let { data } = response
                setUserData(data);
                setUserHasAccess(true);

                if (data.language && data.language != i18n.language) {
                    i18n.changeLanguage(data.language);
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    setUserHasAccess(false);
                } else {
                    navigate('/');
                }
            })
    }, [userHasAccess])

    if (!userdata && userHasAccess === null) {
        return <div>{t('common.loading')}</div>;
    }

    return (
        <HelpScoutTagWrapper includeHelpScoutTag={true}>
            <div className="flex h-screen overflow-hidden  bg-gray-100">

                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                    {/*  Site header */}
                    <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} fullname={userdata.fullname} avatar={userdata.avatar} />

                    <div className="grid grid-cols-3 gap-4 my-4">
                        <div className="col-span-3">
                            <CreateEventTypeContainer
                                styles={styles}
                                userdata={userdata} />
                        </div>
                    </div>
                </div>
            </div>
        </HelpScoutTagWrapper>
    );
}

export default CreateEventType;

import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axiosInstance from '.';

const Redirect401 = () => {
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    navigate("/");
                }
                return Promise.reject(error);
            }
        );
    }, [navigate]);

    return null;
};

export default Redirect401;
import { Button, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import CopyButton from './copyButton'

export default function EventTypeSaved({ open, setOpen, url, handleClose, isPro }) {
    const { t } = useTranslation();

    const actions = [
        {
            id: 1,
            name: t('component.event_type_saved.reminders_feature'),
            href: '/pro-plan',
            imageSrc: '/reminders.png',
            imageAlt: "reminders feature thumbnail",
        },
        {
            id: 2,
            name: t('component.event_type_saved.custom_question_feature'),
            href: '/pro-plan',
            imageSrc: '/custom_question.png',
            imageAlt: "custom question thumbnail",
        },
    ]

    return (
        <Transition show={open}>
            <Dialog as="div" className="relative z-10" onClose={() => { }}>
                <TransitionChild
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <Button
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => {
                                            setOpen(false);
                                            handleClose();
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </Button>
                                </div>
                                <div className="mt-3 text-center sm:my-10">
                                    <DialogTitle as="h3" className="text-xl font-bold leading-6 text-gray-900">
                                        {t('component.event_type_saved.title')} 🚀🚀🚀
                                    </DialogTitle>
                                    <div className='inline-flex w-full justify-center mt-5'>
                                        <span className='inline-flex truncate border border-gray-300 rounded-l-md items-center px-2'>{url}</span>
                                        <CopyButton url={url} />
                                    </div>
                                </div>
                                {!isPro && <div className="md:grid md:grid-cols-2 m-12 gap-x-4">
                                    <div className='md:col-span-2 my-4'>
                                        {t('component.event_type_saved.next_action')}
                                    </div>

                                    {actions.map((action) => (
                                        <div key={action.id} className="md:col-span-1 gap-x-6 gap-y-10">
                                            <div className="group relative">
                                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-t-md border-t border-l border-r border-gray-300 lg:aspect-none group-hover:opacity-75 lg:h-40">
                                                    <img
                                                        src={action.imageSrc}
                                                        alt={action.imageAlt}
                                                        className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                                                    />
                                                </div>
                                                <div className="border-b border-l border-r rounded-b-md border-gray-300">
                                                    <div className="justify-center w-full">
                                                        <h3 className="text-sm text-gray-700 py-4 text-center">
                                                            <a href={action.href}>
                                                                <span aria-hidden="true" className="absolute inset-0" />
                                                                {action.name}
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>}

                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

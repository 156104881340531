import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Dropdown from './dropdown';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline'

const Availability = ({ field, form, defaultAvailability, onInputChange = () => { } }) => {
    const [availability, setAvailability] = useState({});

    // loads defaultAvailability used to edit availability
    useEffect(() => {
        if (defaultAvailability) {
            setAvailability(defaultAvailability);
        }
    }, [])

    // set this value to formik value whenever the availability changes
    useEffect(() => {
        form.setFieldValue(field.name, availability)
        onInputChange(field.name, availability)
    }, [availability])


    const weekdays = [1, 2, 3, 4, 5, 6, 0]

    const handleUpdate = (day, id, field, value) => {
        setAvailability(prevAvailability => {
            // Create a shallow copy of the previous availability
            const tmpAvailability = { ...prevAvailability };


            // if there is no value for that day yet we add the field
            if (!tmpAvailability[day]) { tmpAvailability[day] = [] }

            // Check if the value is empty, and if so, remove the field
            if (value === '') {
                if (tmpAvailability[day][id]) {
                    // Create a new array without the element to remove
                    tmpAvailability[day] = tmpAvailability[day].filter(
                        (_, index) => index !== id
                    );
                }
            } else {
                // Update the field with the new value
                tmpAvailability[day][id] = {
                    ...tmpAvailability[day][id],
                    [field]: value
                }
            }

            return tmpAvailability;
        });
    };

    const { t } = useTranslation();

    return (
        <>
            <span className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{t('component.custom_availability.title')}</span>
            <div className='grid grid-cols-9 gap-2'>
                <div className='col-span-1'></div>
                <div className='col-span-3'>
                    <span className="block tracking-wide text-gray-700 text-xs font-bold my-2">{t('component.custom_availability.from')}</span>
                </div>
                <div className='col-span-3'>
                    <span className="block tracking-wide text-gray-700 text-xs font-bold my-2">{t('component.custom_availability.until')}</span>
                </div>
            </div>

            {
                weekdays.map(day =>
                    <div key={day}>
                        <HourRange
                            day={day}
                            defaultAvailability={availability[day]}
                            handleUpdate={handleUpdate}
                        />
                    </div>)
            }
        </>
    )
}

export default Availability

const HourRange = ({ day, defaultAvailability, handleUpdate }) => {
    const [inputFields, setInputFields] = useState([
        { id: 0, start: '', end: '' },
    ]);
    const [checked, setChecked] = useState(false)


    // loads defaultAvailability used to edit availability
    useEffect(() => {
        if (defaultAvailability && defaultAvailability[0] && defaultAvailability[0].start != '') {
            defaultAvailability.map((defAvailability, idx) => {
                setInputFields(prevInputFields => {
                    // Create a new array with the updated object
                    const updatedFields = [...prevInputFields];

                    // Update the object at the specified position
                    updatedFields[idx] = { id: idx, start: defAvailability.start, end: defAvailability.end };

                    return updatedFields;
                });
            })
            setChecked(true)
        }
    }, [defaultAvailability])

    const { t } = useTranslation();

    const handleChangeInput = (id, field, value) => {
        const newInputFields = inputFields.map(i => {
            if (id === i.id) {
                i[field] = value
            }
            return i;
        })

        setInputFields(newInputFields);
        handleUpdate(day, id, field, value);
    }

    const handleAddFields = () => {
        setInputFields([...inputFields, { id: inputFields.length, start: '', end: '' }])
    }

    const handleRemoveFields = () => {
        handleUpdate(day, inputFields.length - 1, 'start', '');
        handleUpdate(day, inputFields.length - 1, 'end', '');

        const values = [...inputFields];
        values.pop();
        setInputFields(values);
    }

    const handleChangeCheckbox = (day) => {
        setChecked(!checked);

        if (checked) {
            inputFields.map(inputField => {
                handleUpdate(day, inputField.id, 'start', '');
                handleUpdate(day, inputField.id, 'end', '');
            })
        } else {
            inputFields.map(inputField => {
                handleChangeInput(inputField.id, 'start', inputField.start)
                handleChangeInput(inputField.id, 'end', inputField.end)
            })
        }
    }

    const options = []
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
            const hour = i.toString().padStart(2, "0")
            const minutes = (j * 15).toString().padStart(2, "0")
            options.push({ value: `${hour}:${minutes}`, label: `${hour}:${minutes}` })
        }
    }

    return (
        <div className='grid grid-cols-9 gap-2'>
            <div className='col-span-9 md:col-span-1 flex justify-start pt-2 space-x-1'>
                <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    checked={checked}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={() => handleChangeCheckbox(day)}
                />
                <span className='uppercase tracking-wide text-gray-700 text-xs font-bold'>{t('common.weekday.' + day)}</span>
            </div>
            <div className='col-span-8'>
                {checked &&
                    <>
                        {inputFields.map((inputField, idx) => (
                            <div key={`${day}-${inputField.id}`} className='grid grid-cols-9 gap-2 mt-1'>
                                <div className="col-span-4">
                                    <Dropdown
                                        id="start"
                                        options={options}
                                        defaultValue={options.find(option => option.value === inputField.start)}
                                        onChange={option => handleChangeInput(inputField.id, 'start', option.value)}
                                    />
                                </div>
                                <div className="col-span-4">
                                    <Dropdown
                                        id="end"
                                        options={options}
                                        defaultValue={options.find(option => option.value === inputField.end)}
                                        onChange={option => handleChangeInput(inputField.id, 'end', option.value)}
                                    />
                                </div>
                                <div className="col-span-1 space-x-2 flex items-center">
                                    {idx === 0 &&
                                        <>
                                            <button type="button" disabled={inputFields.length === 1} onClick={() => handleRemoveFields()}>
                                                <MinusCircleIcon className="h-5 w-5" />
                                            </button>
                                            <button type="button" onClick={handleAddFields}>
                                                <PlusCircleIcon className="h-5 w-5" />
                                            </button>
                                        </>
                                    }

                                </div>
                            </div >
                        ))}
                    </>
                }
                {!checked &&
                    <div className="col-span-2 justify-self-start">
                        <span className="text-gray-400"> {t('component.custom_availability.unavailable')} </span>
                    </div>}

            </div>
            <Divider />
        </div>
    );
}

const Divider = ({ title }) => {
    return (
        <div className="relative mt-2 mb-2 col-span-9">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-500">{title}</span>
            </div>
        </div>
    )
}
import { useState, useRef, Fragment } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { ShareIcon, XMarkIcon, CodeBracketIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import CodeSnippet from './codeSnippet'
import CopyButton from './copyButton'

export default function EventTypeShare({ open, setOpen, url }) {
    const { t } = useTranslation();
    const [showCodeSnippet, setShowCodeSnippet] = useState(false);
    const closeButtonRef = useRef(null);

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" initialFocus={closeButtonRef} open={open} onClose={() => setOpen(false)}>
                <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <DialogPanel className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div>
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <ShareIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                    {t('component.share_event_type.title')}
                                </DialogTitle>
                                <div className="mt-2 mx-5">
                                    <Divider title={t('component.share_event_type.share_link')} />
                                    <div className='inline-flex w-full justify-center mt-2'>
                                        <span className='inline-flex truncate border border-gray-300 rounded-l-md items-center px-2'>{url}</span>
                                        <CopyButton url={url} />
                                    </div>
                                    <Divider title={t('component.share_event_type.embed_code')} />
                                    <span className="rounded-md shadow-sm">
                                        <div className="inline-flex w-full justify-center items-center px-4 py-2 text-sm font-medium leading-5 text-gray-700 bg-white border border-gray-300 rounded-t-md cursor-pointer hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={() => setShowCodeSnippet(true)}>
                                            <CodeBracketIcon className="h-6 w-6 mx-2" aria-hidden="true" />
                                            <button
                                                type="button"
                                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                {t('component.share_event_type.embed_code')}
                                            </button>
                                        </div>
                                    </span>
                                    {showCodeSnippet &&
                                        <TransitionChild
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className='inline-flex w-full justify-center'>
                                                <CodeSnippet url={url} />
                                            </div>
                                        </TransitionChild>}
                                </div>
                            </div>
                        </div>
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                ref={closeButtonRef}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => {
                                    setOpen(false);
                                    setShowCodeSnippet(false);
                                }}
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>
        </Transition>
    )
}

const Divider = ({ title }) => {
    return (
        <div className="relative mt-8 mb-2">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-500">{title}</span>
            </div>
        </div>
    )
}

import { useState, useEffect, Fragment } from 'react'
import {
    BoltIcon,
    BoltSlashIcon,
    // CubeIcon,
    UserCircleIcon,
} from '@heroicons/react/24/outline'
import { StarIcon } from '@heroicons/react/24/solid'
import { Dialog, Transition, DialogPanel, TransitionChild } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import Header from "../components/header";
import axiosInstance from '../actions';
import Username from "../components/username";
import ZoomConnect from "../components/zoomConnect";



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const AccountPage = () => {
    const [userdata, setUserData] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [openUpdateUsername, setOpenUpdateUsername] = useState(false)

    const { t, i18n } = useTranslation();

    const secondaryNavigation = [
        { name: t('component.account.navigation.general'), href: '#', icon: UserCircleIcon, current: true },
        // { name: 'Plan', href: '#', icon: CubeIcon, current: false },
    ]


    useEffect(() => {
        axiosInstance.get('/home', { withCredentials: true })
            .then(response => {
                let { data } = response
                setUserData(data);
            }).catch(error => console.log(error.message))
    }, [])

    if (userdata == null) {
        return <div>{t('common.loading')}</div>;
    }

    const handleUpdateUsername = (username) => {
        setUserData({
            ...userdata,
            username: username,
        });
        setOpenUpdateUsername(false)
    }

    return (
        <>
            <Header
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                fullname={userdata.fullname}
                avatar={userdata.avatar} />

            <UpdateUsername open={openUpdateUsername} setOpen={setOpenUpdateUsername} handleUpdateUsername={handleUpdateUsername} />

            <div className="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">
                <h1 className="sr-only">{t('component.account.general_settings')}</h1>

                <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                    <nav className="flex-none px-4 sm:px-6 lg:px-0">
                        <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                            {secondaryNavigation.map((item) => (
                                <li key={item.name}>
                                    <a
                                        href={item.href}
                                        className={classNames(
                                            item.current
                                                ? 'bg-gray-50 text-indigo-600'
                                                : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                            'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6'
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                'h-6 w-6 shrink-0'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </aside>

                <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
                    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">{t('component.account.profile.title')}</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                                {t('component.account.profile.subtitle')}
                            </p>

                            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('component.account.profile.fullname')}</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{userdata.fullname}</div>
                                    </dd>
                                </div>
                                {/* <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{userdata.email}</div>
                                    </dd>
                                </div> */}
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('component.account.profile.username')}</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{userdata.username}</div>
                                        <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                            onClick={() => setOpenUpdateUsername(true)}>
                                            {t('common.update')}
                                        </button>
                                    </dd>
                                </div>
                            </dl>
                        </div>

                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900"></h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                                {t('component.account.language_date.subtitle')}
                            </p>

                            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('component.account.language_date.language')}</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{userdata.language == "es" ? "Español" : "English"}</div>
                                    </dd>
                                </div>
                            </dl>

                            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('component.account.language_date.timezone')}</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{userdata.timezone}</div>
                                    </dd>
                                </div>
                            </dl>
                        </div>

                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900"></h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                                {/* {t('component.account.language_date.subtitle')} */}
                                Integrations
                            </p>

                            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Zoom</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        {userdata.zoom_connected ?
                                            <div className="inline-flex items-center space-x-2 text-gray-900">
                                                <BoltIcon className="h-5 w-5" />
                                                <span>Connected</span>
                                            </div> :
                                            <div className="inline-flex items-center space-x-2 text-gray-9000">
                                                <BoltSlashIcon className="h-5 w-5" />
                                                <ZoomConnect username={userdata.username} subscriptionPlan={userdata.subscription_plan} />
                                                <StarIcon className="h-2 w-2 z-10 text-indigo-600" />
                                            </div>
                                        }
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default AccountPage;


const UpdateUsername = ({ open, setOpen, handleUpdateUsername }) => {
    const styles = {
        buttonPrimary:
            'inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-4',
        errorMsg: 'text-red-500 text-sm',
    }

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel>
                                <Username styles={styles} handleUpdateUsername={handleUpdateUsername} action={"update"} />
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

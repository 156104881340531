
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import { PencilSquareIcon, TrashIcon, ShareIcon } from '@heroicons/react/20/solid'
import ConfirmationModal from "./confirmationModal";
import EventTypeShare from "./eventTypeShare";

let EventType = ({ username, eventType, handleEditEventType, handleDeleteEventType }) => {
    const [selectedEventTypeForDeletion, setSelectedEventTypeForDeletion] = useState(null);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [shareModalUrl, setShareModalUrl] = useState("");

    const domain = window.location.origin;
    const { t } = useTranslation();

    if (!eventType) {
        return <div>{t('common.loading')}</div>
    }

    const cancelDeleteEventType = () => {
        setSelectedEventTypeForDeletion(null)
    }

    const handleSelectedEventTypeForDeletion = (idx) => {
        setSelectedEventTypeForDeletion(idx)
    }

    const DeleteEventType = () => {
        handleDeleteEventType(eventType[selectedEventTypeForDeletion].UUID);
        setSelectedEventTypeForDeletion(null);
    }

    const handleShareClick = (url) => {
        setShareModalOpen(true);
        setShareModalUrl(url);
    };

    return (
        <>
            <ConfirmationModal
                open={selectedEventTypeForDeletion != null}
                cancel={cancelDeleteEventType}
                confirm={DeleteEventType}
                title={t('component.event_type.delete.title')}
                body={t('component.event_type.delete.body')}
                confirmButton={t('component.event_type.delete.confirm_button')}
            />
            <div className="flex flex-col justify-center w-full space-y-4">
                <ul className="space-y-4">
                    {eventType.map((et, idx) => (
                        <li key={idx} className="bg-white border border-gray-200 rounded-md group hover:shadow-lg">
                            <div className="flex flex-col items-center px-4 py-4 md:flex-row sm:px-6">
                                <div className="flex items-center flex-1 w-full min-w-0 mr-10">
                                    <div className="block w-full truncate mx-4">
                                        <div className="flex items-center truncate">
                                            <a className="flex space-x-1" href={domain + "/" + username + "/" + et.Name} target="_blank">
                                                <p className="text-base font-medium leading-5 truncate text-blue-800">{et.DisplayName}</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="w-5 h-5 text-gray-500 sm:mr-2 sm:-ml-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14">
                                                    </path>
                                                </svg>
                                            </a>

                                        </div>
                                        <div className="flex mt-2 space-x-4">
                                            <div className="flex items-center text-sm leading-5 text-gray-500 cursor-pointer">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd"></path>
                                                </svg>
                                                <span className="truncate">{et.Duration} {t('common.min')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center w-full mt-4 ml-2 md:w-auto md:mt-0 md:justify-center">
                                    <span className="ml-2 rounded-md shadow-sm">
                                        <a
                                            onClick={() => handleShareClick(domain + "/" + username + "/" + et.Name)}
                                            className="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-gray-700 bg-white border border-gray-300 rounded-md cursor-pointer hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <ShareIcon
                                                className="mr-3 h-5 w-5 text-gray-500"
                                                aria-hidden="true"
                                            />
                                            {t('common.share')}
                                        </a>
                                    </span>
                                    <EventTypeShare
                                        open={shareModalOpen}
                                        setOpen={setShareModalOpen}
                                        url={shareModalUrl}
                                    />

                                    <span className="ml-2 rounded-md shadow-sm">
                                        <a
                                            onClick={() => handleEditEventType(et.UUID)}
                                            className="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-gray-700 bg-white border border-gray-300 rounded-md cursor-pointer hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <PencilSquareIcon
                                                className="mr-3 h-5 w-5 text-gray-500"
                                                aria-hidden="true"
                                            />
                                            {t('common.edit')}
                                        </a>
                                    </span>

                                    <span className="ml-2 rounded-md shadow-sm">
                                        <a
                                            onClick={() => handleSelectedEventTypeForDeletion(idx)}
                                            className="inline-flex items-center px-1 py-2 text-sm font-medium leading-5 text-gray-700 bg-white border border-gray-300 rounded-md cursor-pointer hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <TrashIcon className="mx-1 h-5 w-5 text-gray-500" aria-hidden="true" />
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default EventType;

import React, { useState, useEffect } from 'react';

const Stepper = ({ steps }) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (activeStep < steps.length - 1 && steps[activeStep].completed) {
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, steps]);

  if (steps.every(step => step.completed)) {
    return null;
  }

  return (
    <div>
      {steps.map((step, index) => (
        <div key={index}>
          <div className="flex m-2">
            <div
              className={`h-6 w-6 rounded-full ${
                activeStep >= index ? 'bg-blue-500' : 'bg-gray-300'
              }`}
            >
              <span className="text-white font-bold">{index + 1}</span>
            </div>
            <div
              className={`ml-5 ${
                activeStep >= index ? 'text-gray-900 font-bold' : 'text-gray-500'
              }`}
            >
              {step.label}
            </div>
          </div>
          <div className={`ml-10 ${activeStep !== index && 'hidden'}`}>{step.content}</div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;

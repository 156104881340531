import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { duotoneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CodeBracketIcon } from '@heroicons/react/24/outline'

const CodeSnippet = ({ url }) => {
    const [isCopied, setIsCopied] = useState(false);
    const { t } = useTranslation();

    let code = `
    <iframe 
        src="${url}" 
        width="100%" 
        height="800px"
        frameborder="0"
    ></iframe>
    <span style='font-family: Arial, Helvetica, sans-serif; color: lightgray;'>
        ${t('component.code_snippet.poweredBy')} 
        <a href="${t('component.code_snippet.poweredByLink')}" style="color: gray;">Cronos</a>
    </span>`;

    const handleCopy = () => {
        navigator.clipboard.writeText(code);
        setIsCopied(true);

        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    };

    return (
        <div className="overflow-x-scroll">
            <SyntaxHighlighter language="html" style={duotoneDark}>
                {code}
            </SyntaxHighlighter>
            <span className="rounded-md shadow-sm">
                <div className="inline-flex w-full justify-center items-center px-4 py-2 text-sm font-medium leading-5 text-gray-700 bg-white border border-gray-300 rounded-b-md cursor-pointer hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={handleCopy}>
                    <button>
                        {isCopied ? t('component.code_snippet.copied') : t('component.code_snippet.copy_embed')}
                    </button>
                </div>
            </span>
        </div>
    );
};

export default CodeSnippet;

import { useState } from "react";
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from '../actions/index';
import { useTranslation } from 'react-i18next';


let Signin = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectTo = params.get('redirectTo');
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  let navigate = useNavigate();

  return (
    <div>
      <GoogleLogin
        onSuccess={credentialResponse => {
          const { credential } = credentialResponse;

          axiosInstance.post('/signin',
            {
              credential: credential,
              language: i18n.language,
              timezone: timezone,
            }, {
            withCredentials: true,
          })
            .then(() => { navigate(redirectTo ? `${redirectTo}` : '/home') })
            .catch(error => { console.log("code: ", error) })
        }}
        locale={i18n.language}
        onError={() => {
          console.log('Login Failed');
        }}
        useOneTap
      >
      </GoogleLogin>
    </div>
  )
}

export default Signin;
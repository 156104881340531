import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition, Switch } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { loadStripe } from '@stripe/stripe-js';
import { XMarkIcon, RocketLaunchIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/20/solid'
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';

import axiosInstance from '../actions/index';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_APIKEY);

const CheckoutForm = ({ open, setOpen, onClose, feature }) => {
    const [clientSecret, setClientSecret] = useState('');
    const [annualBilling, setAnnualBilling] = useState(true)
    const { t } = useTranslation();

    const includedFeatures = [
        t('component.stripe_checkout.feature_reminders'),
        t('component.stripe_checkout.feature_custom_question')
    ]
    const commingFeatures = [
        t('component.stripe_checkout.feature_branding'),
        t('component.stripe_checkout.feature_workspaces')
    ]

    useEffect(() => {
        // Create a Checkout Session as soon as dialog is open
        if (open) {
            axiosInstance.post('/create-checkout-session', { "annual_billing": annualBilling }, { withCredentials: true })
                .then((res) => { setClientSecret(res.data.clientSecret) });
        }
    }, [open]);

    useEffect(() => {
        // Create a new Checkout Session if the plan changes
        if (open) {
            axiosInstance.post('/create-checkout-session', { "annual_billing": annualBilling }, { withCredentials: true })
                .then((res) => { setClientSecret(res.data.clientSecret) });
        }
    }, [annualBilling]);




    return (
        <Transition.Root show={open} as={Fragment}>
            {/* Dialog onClose defines the behaviour when we click outside of the dialog, currently we don't want to do anything */}
            <Dialog as="div" className="relative z-10" onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto pt-6">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-0 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-8xl sm:p-6">
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => {
                                            setOpen(false);
                                            if (onClose) {
                                                onClose();
                                            }
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="bg-white py-24 sm:py-10">
                                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                        <div className="mx-auto max-w-2xl sm:text-center">
                                            <h2 className="text-xl md:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('component.stripe_checkout.upgrade_title')}</h2>
                                        </div>
                                        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-10 lg:mx-0 lg:flex lg:max-w-none">
                                            <div className="bg-indigo-500 p-8 sm:p-10 lg:flex-auto rounded-3xl">
                                                <div className="flex flex-col mt-10 space-y-4 items-center">
                                                    <h1 className="text-xl font-semibold leading-6 text-white">{t(`component.stripe_checkout.feature_${feature}`)}</h1>
                                                    <img className="hidden sm:block rounded-md" src={t(`component.stripe_checkout.feature_${feature}_gif`)} alt="Reminders" />
                                                </div>

                                                <div className="mt-10 flex items-center gap-x-4">
                                                    <h4 className="flex-none text-sm font-semibold leading-6 text-white">{t('component.stripe_checkout.included')}</h4>
                                                    <div className="h-px flex-auto bg-gray-200" />
                                                </div>
                                                <ul
                                                    role="list"
                                                    className="mt-8 text-sm leading-6 text-gray-300 space-y-4"
                                                >
                                                    {includedFeatures.map((feature) => (
                                                        <li key={feature} className="flex gap-x-3">
                                                            <CheckIcon className="h-6 w-5 flex-none text-white-600" aria-hidden="true" />
                                                            {feature}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="mt-10 flex items-center gap-x-4">
                                                    <h4 className="text-sm font-semibold leading-6 text-white flex-wrap">{t('component.stripe_checkout.in_progress')}</h4>
                                                    <div className="h-px flex-auto bg-gray-200" />
                                                </div>
                                                <ul
                                                    role="list"
                                                    className="mt-8 text-sm leading-6 text-gray-300 space-y-4"
                                                >
                                                    {commingFeatures.map((feature) => (
                                                        <li key={feature} className="flex gap-x-3">
                                                            <RocketLaunchIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                                                            {feature}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="lg:w-full lg:max-w-md lg:flex-shrink-0 pb-5">
                                                {/* switch button */}
                                                <div className="flex mt-10 px-2 space-x-4 justify-center">
                                                    <span className="text-xl md:text-4xl py-2">{annualBilling ? t('component.stripe_checkout.annual_price_monthly') : t('component.stripe_checkout.monthly_price')}</span>
                                                    <Switch.Group as="div" className="flex items-center">
                                                        <Switch
                                                            checked={annualBilling}
                                                            onChange={setAnnualBilling}
                                                            className={classNames(
                                                                annualBilling ? 'bg-indigo-600' : 'bg-gray-200',
                                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                                            )}
                                                        >
                                                            <span
                                                                aria-hidden="true"
                                                                className={classNames(
                                                                    annualBilling ? 'translate-x-5' : 'translate-x-0',
                                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                )}
                                                            />
                                                        </Switch>
                                                        <Switch.Label as="span" className="flex flex-col ml-3 text-sm">
                                                            <span className="font-medium text-gray-900">{t('component.stripe_checkout.annual_billing')}</span>{' '}
                                                            <span className="text-gray-500 text-xs">{t('component.stripe_checkout.annual_savings')}</span>
                                                        </Switch.Label>
                                                    </Switch.Group>
                                                </div>
                                                <div id="checkout" className="px-1">
                                                    {clientSecret && (
                                                        <EmbeddedCheckoutProvider
                                                            key={clientSecret} // to trigger re-render when switch changes
                                                            stripe={stripePromise}
                                                            options={{ clientSecret }}
                                                        >
                                                            <EmbeddedCheckout />
                                                        </EmbeddedCheckoutProvider>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default CheckoutForm;


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

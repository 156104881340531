import React, { useEffect } from 'react';
import axiosInstance from '../actions';
import { useNavigate, useLocation } from 'react-router-dom';

const ZoomCallback = () => {
    const location = useLocation();
    let navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const code = query.get('code');
    const state = query.get('state');

    useEffect(() => {
        if (code) {
            axiosInstance.post('/zoom/callback', { "code": code, "state": state }, { withCredentials: true })
                .then(() => {
                    // Handle token storage and redirection here
                    navigate('/account');
                })
                .catch(error => {
                    console.error('Error exchanging code for token:', error);
                });
        }
    }, []);

    return <div>Logging in with Zoom...</div>;
};

export default ZoomCallback;

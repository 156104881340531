import { useTranslation } from 'react-i18next'
import ConnectGoogleCalendar from "../components/connectGoogleCalendar";


let SignUp = () => {
    const { t } = useTranslation()
    return (
        <>
            <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
                <div className="w-full max-w-sm space-y-10">
                    <div>
                        <img
                            className="mx-auto h-10 w-auto"
                            src="logo512.png"
                            alt="Your Company"
                        />
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            {t('signup.title')}
                        </h2>
                        <p className="text-gray-400 text-xs">{t('signup.second_title')}</p>
                    </div>

                    <div className="flex items-center justify-center">
                        <ConnectGoogleCalendar>
                            <div className="flex items-center px-6 py-2 border-2 bg-white border-black text-black  font-medium text-xs leading-tight rounded-full hover:bg-gray-200 hover:bg-opacity-3 focus:outline-none focus:ring-0 transition duration-150 ease-in-out m-2">
                                <div className="w-4 h-4 mr-2">
                                    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="#4285F4" d="M17.64 9.204c0-.63-.056-1.242-.16-1.836H9v3.47h4.812c-.204 1.132-.796 2.084-1.668 2.73v2.268h2.704c1.58-1.452 2.484-3.584 2.484-6.632z" />
                                        <path fill="#34A853" d="M9 18c2.28 0 4.18-.756 5.592-2.048l-2.704-2.268c-.752.508-1.716.812-2.888.812-2.22 0-4.096-1.494-4.756-3.5H1.776v2.204C3.168 15.204 5.58 18 9 18z" />
                                        <path fill="#FBBC05" d="M4.244 10.756c-.18-.54-.284-1.116-.284-1.756s.104-1.216.284-1.756V4.796H1.776C.644 6.232 0 8.044 0 10s.644 3.768 1.776 5.204l2.468-2.448z" />
                                        <path fill="#EA4335" d="M9 3.6c1.252 0 2.38.432 3.268 1.288l2.448-2.468C13.18.888 11.28 0 9 0 5.58 0 3.168 2.796 1.776 4.796l2.468 2.448C4.904 4.494 6.78 3.6 9 3.6z" />
                                        <path fill="none" d="M0 0h18v18H0z" />
                                    </svg>
                                </div>
                                <span className="text-sm">{t('component.connectGoogleCalendar.title')}</span>
                            </div>
                        </ConnectGoogleCalendar>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp
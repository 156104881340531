import { useTranslation } from 'react-i18next'

const BookingHeader = ({ eventTypeInfo }) => {
    const { t } = useTranslation();

    let eventTypeDuration
    if (eventTypeInfo && eventTypeInfo.duration != "") {

        // stringify the event type duration and remove the 'm' in case is present
        eventTypeDuration = eventTypeInfo.duration + ''
        eventTypeDuration = eventTypeDuration.replace(/m/g, '');

        if (eventTypeDuration % 60 === 0) {
            eventTypeDuration = eventTypeDuration / 60 + ' ' + t('common.hour')
        } else {
            eventTypeDuration = eventTypeDuration + ' ' + t('common.min')
        }
    }

    return (
        <div className="py-8 px-2">
            <h1 className="text-gray-900 text-xl leading-tight font-bold mt-5 uppercase">{eventTypeInfo.display_name} </h1>
            <div className="flex justify-center items-center mt-5">
                <div className="w-4 h-4 mr-2 text-gray-400">
                    <svg data-id="details-item-icon" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" role="img">
                        <path d="M.5 5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 1 0-9 0Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M5 3.269V5l1.759 2.052" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </div>
                <span className="text-gray-400 text-md leading-tight font-bold lowercase">{eventTypeDuration}</span>
            </div>
            <div className='flex mt-5 p-4 text-start'>
                <span className="text-gray-400 text-xs">{eventTypeInfo.description} </span>
            </div>
        </div>
    )
}

export default BookingHeader;
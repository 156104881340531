import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import BookingContainer from '../containers/bookingContainer';
import BookingConfirmationForm from '../components/bookingConfirmationForm';
import BookingHeader from '../components/bookingHeader';
import ConfirmedAppointment from '../components/confirmedAppointment';
import axiosInstance from '../actions/index';
import * as moment from 'moment-timezone'


const BookingPage = () => {
    const { t, i18n } = useTranslation();
    const { username, calendar_id } = useParams();

    const [eventTypeInfo, setEventTypeInfo] = useState("");

    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [confirmedAppointment, setConfirmedAppointment] = useState(null);
    const [timezone, setTimezone] = useState(moment.tz.guess());

    useEffect(() => {
        fetchEventTypeInfo(username, calendar_id).then(eventTypeInfo => {
            if (eventTypeInfo) {
                setEventTypeInfo(eventTypeInfo)
            }
        })
    }, []);

    let confirmAppointment = (eventDateUTC, values) => {
        let data = {
            ...values,
            'date': eventDateUTC,
            'username': username,
            'calendar_id': calendar_id,
            'guest_email': values.guest_email,
            'guest_name': values.guest_name,
            'guest_timezone': timezone,
            'guest_language': i18n.language,
        }

        axiosInstance.post('/schedule', data, { withCredentials: true })
            .then(response => {
                setConfirmedAppointment({
                    "event_id": response.data.event_id,
                    "event_type_id": response.data.event_type_id
                })
            })
            .catch(error => console.log(error.message))
    }

    let poweredByHref = '/?utm_campaign=' + calendar_id + '&utm_source=' + username + '&utm_medium=poweredby&utm_term=' + i18n.language
    if (i18n.language != "en") {
        poweredByHref = '/' + i18n.language + poweredByHref
    }

    return (
        <div name="container" className="flex justify-center items-center md:h-screen bg-gray-50">
            <div name="content" className=" bg-white shadow-lg rounded-lg w-full sm:w-2/3 max-w-4xl">
                <Link
                    to={poweredByHref}
                    target='_blank'
                    className="flex justify-center border border-gray-200 bg-primary rounded-br-xl shadow-2xl w-48 text-white">
                    {t('common.powered_by')}
                </Link>

                <div className='md:grid md:grid-cols-7'>
                    <div name="calendar_header" className="md:col-span-2">
                        <BookingHeader eventTypeInfo={eventTypeInfo} />
                    </div>

                    <div className='md:col-span-5'>
                        {!confirmedAppointment && !selectedTimeSlot &&
                            <div className="px-2">
                                <BookingContainer
                                    username={username}
                                    calendar_id={calendar_id}
                                    handleSelectTimeSlot={setSelectedTimeSlot}
                                    timezone={timezone}
                                    setTimezone={setTimezone}
                                />
                            </div>}

                        {!confirmedAppointment && selectedTimeSlot &&
                            <BookingConfirmationForm
                                selectedTimeSlot={selectedTimeSlot}
                                setSelectedTimeSlot={setSelectedTimeSlot}
                                confirmAppointment={confirmAppointment}
                                timezone={timezone}
                                customQuestion={eventTypeInfo.custom_question ? eventTypeInfo.custom_question : []}
                            />}

                        {confirmedAppointment && <ConfirmedAppointment event_id={confirmedAppointment.event_id} event_type_id={confirmedAppointment.event_type_id} />}
                    </div>
                </div>
            </div>
        </div>);
}

export default BookingPage;

let fetchEventTypeInfo = async (username, calendar_id) => {
    const resp = await axiosInstance.get('/event-type-info',
        {
            params: {
                username: username,
                calendar_id: calendar_id
            }
        })

    return resp.data;
}
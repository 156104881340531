import { useParams } from "react-router-dom";
import ConfirmedAppointment from "../components/confirmedAppointment";

let ConfirmedAppointmentPage = () => {
    const { event_type_id, event_id } = useParams();

    return (
        <ConfirmedAppointment event_id={event_id}  event_type_id={event_type_id} />
    );
}
export default ConfirmedAppointmentPage;

import React, { useState } from 'react';
import CheckoutForm from './stripeCheckout';



const ZoomConnect = ({ username, subscriptionPlan }) => {
    const [showPro, setShowPro] = useState(false);

    const handleLogin = () => {
        if (subscriptionPlan == "free") {
            setShowPro(true);
            return
        }

        const clientId = process.env.REACT_APP_ZOOM_CLIENT_ID;
        const redirectUri = process.env.REACT_APP_ZOOM_REDIRECT_URL;
        const zoomAuthUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${username}`;
        window.location.href = zoomAuthUrl;
    };


    return (
        <>
            <CheckoutForm open={showPro} setOpen={setShowPro} feature="custom_question" />
            <button onClick={handleLogin} className='font-semibold text-indigo-600 hover:text-indigo-500'>
                Connect Zoom
            </button>

        </>
    );

};

export default ZoomConnect;

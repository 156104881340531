import React from 'react';

import { useTranslation } from 'react-i18next'


const SaveButton = ({ isSaving }) => {
    const { t } = useTranslation();

    return (
        <button
            disabled={isSaving}
            className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            {isSaving ? (
                <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <circle className="opacity-25" cx="12" cy="12" r="10" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V1.5"></path>
                </svg>
            ) : null}
            {isSaving ? t('common.saving') : t('common.save')}
        </button>
    );
};

export default SaveButton;

import { useTranslation } from 'react-i18next'
import Signin from '../components/signin';

let Login = () => {
    const { t } = useTranslation()
    return (
        <>
            <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
                <div className="w-full max-w-sm space-y-10">
                    <div>
                        <img
                            className="mx-auto h-10 w-auto"
                            src="logo512.png"
                            alt="Your Company"
                        />
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            {t('login.title')}
                        </h2>
                        <p className="text-gray-400 text-xs">{t('login.second_title')}</p>
                    </div>

                    <div className="flex items-center justify-center">
                        <Signin />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
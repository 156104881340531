import React, { useState } from "react";

const Alert = ({ color, message, type }) => {
    const [showAlert, setShowAlert] = useState(true);
    const bgColorClass = {
        green: "bg-green-500",
        red: "bg-red-500",
        yellow: "bg-yellow-500",
        blue: "bg-blue-500",
    }[color] || "bg-gray-500"; // Default to gray if color is not found

    return (
        <>
            {showAlert ? (
                <div className={`text-white px-6 py-2 border-0 rounded relative mb-4 ${bgColorClass}`}>
                    <span className="text-xl inline-block mr-5 align-middle">
                        <i className="fas fa-bell" />
                    </span>
                    <span className="inline-block align-middle mr-8">
                        <b className="capitalize">{type}!</b> {message}
                    </span>
                    <button
                        className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-2 mr-6 outline-none focus:outline-none"
                        onClick={() => setShowAlert(false)}
                    >
                        <span>×</span>
                    </button>
                </div>
            ) : null}
        </>
    );
};

export default Alert;
import React, { useState, useEffect } from 'react';
import { StarIcon } from '@heroicons/react/24/solid'
import { QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid';

import { trackEvent } from "../utils/tracking";
import CheckoutForm from './stripeCheckout';
import axiosInstance from '../actions/index';

const CustomQuestion = ({ field, form, defaultCustomQuestion, onInputChange = () => { } }) => {
    const [customQuestion, setCustomQuestion] = useState([]);
    const [customQ, setCustomQ] = useState("");

    const [showPro, setShowPro] = useState(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState("");

    // loads defaultCustomQuestion used to edit customQuestion
    useEffect(() => {
        if (defaultCustomQuestion) {
            const preloadedCustomQuestion = defaultCustomQuestion.map(qc => {
                return { id: qc.id, text: qc.text, type: qc.type }
            })
            setCustomQuestion(preloadedCustomQuestion);
        }
    }, [])


    useEffect(() => {
        form.setFieldValue(field.name, customQuestion)
        onInputChange(field.name, customQuestion)
    }, [customQuestion]);

    useEffect(() => {
        axiosInstance.get('/home', { withCredentials: true })
            .then(response => {
                let { data } = response
                setSubscriptionPlan(data.subscription_plan);
            })
            .catch(error => {
                console.log(error)
            })
    }, [showPro]);

    const { t } = useTranslation();

    const handleAddQuestion = () => {
        if (subscriptionPlan == "free") {
            trackEvent("show_pro_checkout", { feature: "custom_question" });
            setShowPro(true);
            return
        }

        if (customQ != "") {
            setCustomQuestion([...customQuestion, { id: uuidv4(), text: customQ, type: 'text' }])
            setCustomQ("")
        }
    }

    const handleRemoveQuestion = (idx) => {
        const values = [...customQuestion];
        values.splice(idx, 1);
        setCustomQuestion(values);
    }

    return (
        <>
            <CheckoutForm open={showPro} setOpen={setShowPro} feature="custom_question" />
            <span className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 w-full">{t('component.custom_question.title')}</span>

            <div className='grid grid-cols-4 gap-2 w-full my-2'>
                <div className='col-span-3 flex justify-start space-x-2'>
                    <input
                        className="w-full self-center rounded border-gray-300 text-sm bg-gray-200 text-gray-600"
                        value={t('component.custom_question.name')}
                        disabled={true}
                    />
                </div>
                <div className='col-span-3 flex justify-start space-x-2'>
                    <input
                        className="w-full self-center rounded border-gray-300 text-sm bg-gray-200 text-gray-600"
                        value={t('component.custom_question.email')}
                        disabled={true}
                    />
                </div>
            </div >

            {
                customQuestion.map((question, idx) => (
                    <div className='grid grid-cols-4 gap-2 w-full my-2' key={`custom_question_${idx}`}>
                        <div className='col-span-3 flex justify-start space-x-2'>
                            <input
                                className="w-full self-center rounded border-gray-300 text-sm"
                                value={question.text}
                                disabled={true}
                            />
                        </div>
                        <div className='col-span-1 flex justify-end align-middle'>
                            <button type="button" disabled={customQuestion.length === 0} onClick={() => handleRemoveQuestion(idx)}>
                                <XMarkIcon className="h-5 w-5" />
                            </button>
                        </div>
                    </div>
                ))
            }


            <div className="grid grid-cols-4 gap-2 w-full">
                <div className='flex col-span-4 sm:col-span-3 justify-center sm:justify-start items-center space-x-2'>
                    <input
                        placeholder={t('component.custom_question.placeholder')}
                        className="h-9 w-full rounded border-gray-300 text-sm"
                        onChange={(e => setCustomQ(e.target.value))}
                        value={customQ}
                    />
                </div>
                <div className='flex col-span-4 justify-center sm:col-span-1 sm:justify-end'>
                    <button
                        type="button"
                        className="rounded-md bg-white px-4 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={handleAddQuestion}>
                        <div className="flex justify-end w-full">
                            <StarIcon className="absolute h-2 w-2 z-10 text-indigo-600" />
                        </div>
                        {t('component.custom_question.add')}
                    </button>
                </div>
            </div>




        </>
    )
}

export default CustomQuestion;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import Booking from '../components/booking';
import * as moment from 'moment-timezone'
import BookingConfirmationForm from '../components/bookingConfirmationForm';
import ConnectGoogleCalendar from "../components/connectGoogleCalendar";

let Landing = ({ styles }) => {
    const { t } = useTranslation();
    const [userHasAccess, setUserHasAccess] = useState(null);

    return (
        // <!--Nav-->
        <div className="leading-normal tracking-normal text-white bg-gradient-to-t from-blue-500 to-black">
            <nav id="header" className="fixed w-full z-30 top-0 text-white">
                <div className="w-full container mx-auto flex justify-between items-center mt-0 py-2">
                    <div className="pl-4">
                        <a className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">
                            CRONOS
                        </a>
                    </div>
                    <div>
                        <Link
                            to="/login"
                            className=" bg-white text-black font-bold rounded-full my-6 py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                        >
                            {t('landing.login_button')}
                        </Link>
                    </div>

                    {/* <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20" id="nav-content">
                        <ul className="list-reset lg:flex justify-end flex-1 items-center">
                            <li className="mr-3">
                                <a className="inline-block py-2 px-4 text-black font-bold no-underline" href="#">Active</a>
                            </li>
                            <li className="mr-3">
                                <a className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4" href="#">link</a>
                            </li>
                            <li className="mr-3">
                                <a className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4" href="#">link</a>
                            </li>
                        </ul>
                        <button
                            id="navAction"
                            className="mx-auto lg:mx-0 bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                        >
                            Action
                        </button> 
                    </div> */}
                </div>
                <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
            </nav>
            {/* <!--Hero--> */}
            <div className="pt-24">
                <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                    {/* <!--Left Col--> */}
                    <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left mb-20">
                        <h1 className="my-4 text-5xl font-bold leading-tight">
                            {t('landing.title.first_title')}
                        </h1>
                        <p className="leading-normal text-2xl mb-8">
                            {t('landing.title.second_title')}
                        </p>
                        <div id='signin' className="flex w-full justify-center md:justify-start">
                            <ConnectGoogleCalendar>
                                <div className="flex items-center px-6 py-2 border-2 bg-white border-black text-black  font-medium text-xs leading-tight rounded-full hover:bg-gray-200 hover:bg-opacity-3 focus:outline-none focus:ring-0 transition duration-150 ease-in-out m-2">
                                    <div className="w-4 h-4 mr-2">
                                        <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="#4285F4" d="M17.64 9.204c0-.63-.056-1.242-.16-1.836H9v3.47h4.812c-.204 1.132-.796 2.084-1.668 2.73v2.268h2.704c1.58-1.452 2.484-3.584 2.484-6.632z" />
                                            <path fill="#34A853" d="M9 18c2.28 0 4.18-.756 5.592-2.048l-2.704-2.268c-.752.508-1.716.812-2.888.812-2.22 0-4.096-1.494-4.756-3.5H1.776v2.204C3.168 15.204 5.58 18 9 18z" />
                                            <path fill="#FBBC05" d="M4.244 10.756c-.18-.54-.284-1.116-.284-1.756s.104-1.216.284-1.756V4.796H1.776C.644 6.232 0 8.044 0 10s.644 3.768 1.776 5.204l2.468-2.448z" />
                                            <path fill="#EA4335" d="M9 3.6c1.252 0 2.38.432 3.268 1.288l2.448-2.468C13.18.888 11.28 0 9 0 5.58 0 3.168 2.796 1.776 4.796l2.468 2.448C4.904 4.494 6.78 3.6 9 3.6z" />
                                            <path fill="none" d="M0 0h18v18H0z" />
                                        </svg>
                                    </div>
                                    <span className="text-sm">{t('component.connectGoogleCalendar.title')}</span>
                                </div>
                            </ConnectGoogleCalendar>
                        </div>
                    </div>

                    {/* <!--Right Col--> */}
                    <div className="relative w-full md:w-3/5 text-center">
                        <LandingBooking />
                    </div>
                </div>
            </div>
            <div className="mt-12 lg:-mt-24">
                <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                            <path
                                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                opacity="0.100000001"
                            ></path>
                            <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-4" opacity="0.200000003"></path>
                        </g>
                        <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <path
                                d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"
                            ></path>
                        </g>
                    </g>
                </svg>
            </div>
            <section className="bg-white border-b py-8">
                <div className="container max-w-5xl mx-auto m-8">
                    <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                        {t('landing.features_title')}
                    </h2>
                    <div className="w-full mb-4">
                        <div className="h-1 mx-auto bg-black w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-1/2 p-6">
                            <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                                {t('landing.features.save_time_title')}
                            </h3>
                            <p className="text-gray-600 mb-8">
                                {t('landing.features.save_time_description')}
                                <br />
                                <br />
                            </p>
                        </div>
                        <div className="flex w-full justify-center sm:w-1/2 p-6">
                            <img src="/increaseProductivity.svg" alt="Increase productivity" />
                        </div>
                    </div>
                    <div className="flex flex-wrap flex-col-reverse sm:flex-row">
                        <div className="flex w-full justify-center sm:w-1/2 p-6 mt-6">
                            <img src="/streamlineCommunication.svg" alt="Increase productivity" />
                        </div>
                        <div className="w-full sm:w-1/2 p-6 mt-6">
                            <div className="align-middle">
                                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                                    {t('landing.features.stream_line_title')}
                                </h3>
                                <p className="text-gray-600 mb-8">
                                    {t('landing.features.stream_line_description')}
                                    <br />
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-1/2 p-6">
                            <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                                {t('landing.features.flexibility_title')}
                            </h3>
                            <p className="text-gray-600 mb-8">
                                {t('landing.features.flexibility_description')}
                                <br />
                                <br />
                            </p>
                        </div>
                        <div className="flex w-full justify-center sm:w-1/2 p-6">
                            <img src="/flexibility.svg" alt="Increase productivity" />
                        </div>
                    </div>
                    <div className="flex flex-wrap flex-col-reverse sm:flex-row">
                        <div className="flex w-full justify-center sm:w-1/2 p-6 mt-6">
                            <img src="/affordable.svg" alt="Increase productivity" />
                        </div>
                        <div className="w-full sm:w-1/2 p-6 mt-6">
                            <div className="align-middle">
                                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                                    {t('landing.features.affordable_title')}
                                </h3>
                                <p className="text-gray-600 mb-8">
                                    {t('landing.features.affordable_description')}
                                    <br />
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-gray-100 py-8">
                <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
                    <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                        {t('landing.pricing_title')}
                    </h2>
                    <div className="w-full mb-4">
                        <div className="h-1 mx-auto bg-black w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>
                    <div className="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
                        <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                            <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                                <div className="p-8 text-3xl font-bold text-center border-b-4">
                                    {t('landing.pricing.free_title')}
                                </div>
                                <ul className="w-full text-center text-sm">
                                    <li className="border-b py-4">{t('landing.pricing.free_description_1')}</li>
                                    <li className="border-b py-4">{t('landing.pricing.free_description_2')}</li>
                                    <li className="border-b py-4">{t('landing.pricing.free_description_3')}</li>
                                    <li className="border-b py-4">{t('landing.pricing.free_description_4')}</li>
                                </ul>
                            </div>
                            <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                                <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                                    €0
                                    <span className="text-base"> {t('landing.pricing.usuarios')}</span>
                                </div>
                                <div className="flex items-center justify-center">
                                    <ConnectGoogleCalendar>
                                        <div className="mx-auto lg:mx-0 bg-black text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                            <span >{t('landing.sign_up_button')}</span>
                                        </div>
                                    </ConnectGoogleCalendar>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
                            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                                <div className="w-full p-8 text-3xl font-bold text-center"> {t('landing.pricing.pro_title')}</div>
                                <div className="h-1 w-full bg-black my-0 py-0 rounded-t"></div>
                                <ul className="w-full text-center text-base font-bold">
                                    <li className="border-b py-4">{t('landing.pricing.pro_all_in_free')}</li>
                                    <li className="border-b py-4">{t('landing.pricing.pro_description_1')}</li>
                                    <li className="border-b py-4">{t('landing.pricing.pro_description_2')}</li>
                                    <li className="border-b py-4">{t('landing.pricing.pro_description_3')}</li>
                                </ul>
                            </div>
                            <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                                <div className="w-full pt-6 text-4xl font-bold text-center">
                                    €5
                                    <span className="text-base"> {t('landing.pricing.usuarios')}</span>
                                </div>
                                <div className="flex items-center justify-center">
                                    <button className="mx-auto lg:mx-0 bg-black text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                        <Link to="/pro-plan">{t('landing.sign_up_button')}</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/*<div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                            <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                                <div className="p-8 text-3xl font-bold text-center border-b-4">
                                    Pro
                                </div>
                                <ul className="w-full text-center text-sm">
                                    <li className="border-b py-4">Thing</li>
                                    <li className="border-b py-4">Thing</li>
                                    <li className="border-b py-4">Thing</li>
                                </ul>
                            </div>
                            <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                                <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                                    £x.99
                                    <span className="text-base">/ per user</span>
                                </div>
                                <div className="flex items-center justify-center">
                                    <button className="mx-auto lg:mx-0 bg-black text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <!-- Change the colour #f8fafc to match the previous section colour --> */}
            <svg className="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
                        <g className="wave" fill="#f8fafc">
                            <path
                                d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"
                            ></path>
                        </g>
                        <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                            <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                                <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                                <path
                                    d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                    opacity="0.100000001"
                                ></path>
                                <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" opacity="0.200000003"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <section className="container mx-auto text-center py-6 mb-12">
                <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
                    {t('landing.still_thinking_title')}
                </h2>
                <div className="w-full mb-4">
                    <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
                </div>
                <h3 className="my-4 text-3xl leading-tight">
                    {t('landing.still_thinking_description')}
                </h3>
                <div className="flex w-full justify-center">
                    <ConnectGoogleCalendar>
                        <div className="flex items-center px-6 py-2 border-2 bg-white border-black text-black  font-medium text-xs leading-tight rounded-full hover:bg-gray-200 hover:bg-opacity-3 focus:outline-none focus:ring-0 transition duration-150 ease-in-out m-2">
                            <div className="w-4 h-4 mr-2">
                                <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#4285F4" d="M17.64 9.204c0-.63-.056-1.242-.16-1.836H9v3.47h4.812c-.204 1.132-.796 2.084-1.668 2.73v2.268h2.704c1.58-1.452 2.484-3.584 2.484-6.632z" />
                                    <path fill="#34A853" d="M9 18c2.28 0 4.18-.756 5.592-2.048l-2.704-2.268c-.752.508-1.716.812-2.888.812-2.22 0-4.096-1.494-4.756-3.5H1.776v2.204C3.168 15.204 5.58 18 9 18z" />
                                    <path fill="#FBBC05" d="M4.244 10.756c-.18-.54-.284-1.116-.284-1.756s.104-1.216.284-1.756V4.796H1.776C.644 6.232 0 8.044 0 10s.644 3.768 1.776 5.204l2.468-2.448z" />
                                    <path fill="#EA4335" d="M9 3.6c1.252 0 2.38.432 3.268 1.288l2.448-2.468C13.18.888 11.28 0 9 0 5.58 0 3.168 2.796 1.776 4.796l2.468 2.448C4.904 4.494 6.78 3.6 9 3.6z" />
                                    <path fill="none" d="M0 0h18v18H0z" />
                                </svg>
                            </div>
                            <span className="text-sm">{t('component.connectGoogleCalendar.title')}</span>
                        </div>
                    </ConnectGoogleCalendar>
                </div>
            </section>
            {/* <!--Footer--> */}
            <footer className="bg-white">
                <div className="container mx-auto px-8">
                    <div className="w-full flex flex-col md:flex-row py-6">
                        <div className="flex-1 mb-6 text-black">
                            <a className="text-pink-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">
                                CRONOS
                            </a>
                        </div>
                        {/* <div className="flex-1">
                            <p className="uppercase text-gray-500 md:mb-6">Links</p>
                            <ul className="list-reset mb-6">
                                <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a href="#" className="no-underline text-gray-800 hover:text-pink-500">FAQ</a>
                                </li>
                                <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a href="#" className="no-underline text-gray-800 hover:text-pink-500">Help</a>
                                </li>
                                <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a href="#" className="no-underline text-gray-800 hover:text-pink-500">Support</a>
                                </li>
                            </ul>
                        </div> */}
                        <div className="flex-1">
                            <p className="uppercase text-gray-500 md:mb-6">{t('landing.footer.legal_title')}</p>
                            <ul className="list-reset mb-6">
                                <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <Link to="/privacy" className="no-underline text-gray-800 hover:text-pink-500">{t('landing.footer.legal_privacy')}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="flex-1">
                            <p className="uppercase text-gray-500 md:mb-6">{t('landing.footer.company_title')}</p>
                            <ul className="list-reset mb-6">
                                <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <a href="https://blog.cronoscal.com" className="no-underline text-gray-800 hover:text-pink-500">{t('landing.footer.blog')}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}


export default Landing;


const LandingBooking = () => {
    const { t } = useTranslation();
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [timezone, setTimezone] = useState(moment.tz.guess());
    const [confirmedAppointment, setConfirmedAppointment] = useState(null);

    const today = moment().format('YYYY-MM-DD');
    const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');

    const availableSlots = {
        [today]: [
            "09:00",
            "09:15",
            "09:30",
            "09:45"
        ],
        [tomorrow]: [
            "09:00",
            "09:15",
            "09:30",
            "09:45"
        ],
    }

    return (
        <div className=" bg-gray-50 shadow-2xl shadow-slate-300 rounded-lg">
            <div className="shadow-lg rounded-lg w-full">
                {!confirmedAppointment &&
                    <>
                        <div name="calendar_header" className="py-8 border-b border-gray-100">
                            <h2 className="text-gray-400 text-md leading-tight font-bold m-5"> {t('landing.booking.host_name')}</h2>
                            <h1 className="text-gray-900 text-xl leading-tight font-bold m-5 uppercase"> {t('landing.booking.event_name')}</h1>
                            <div className="flex justify-center items-center">
                                <div className="w-4 h-4 mr-2 text-gray-400">
                                    <svg data-id="details-item-icon" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" role="img">
                                        <path d="M.5 5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 1 0-9 0Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M5 3.269V5l1.759 2.052" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </div>
                                <span className="text-gray-400 text-md leading-tight font-bold lowercase">{30} {t('common.min')}</span>
                            </div>
                        </div>
                        <div className="text-gray-900 py-5">
                            {!selectedTimeSlot &&
                                <Booking
                                    availableSlots={availableSlots}
                                    handleSelectTimeSlot={setSelectedTimeSlot}
                                    timezone={timezone}
                                    setTimezone={setTimezone}
                                />}
                            {selectedTimeSlot &&
                                <BookingConfirmationForm
                                    selectedTimeSlot={selectedTimeSlot}
                                    setSelectedTimeSlot={setSelectedTimeSlot}
                                    confirmAppointment={setConfirmedAppointment}
                                    timezone={timezone}
                                    customQuestion={[]}
                                />}
                        </div>
                    </>}
                {confirmedAppointment &&
                    <div className="bg-indigo-100 rounded-lg">
                        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                {t('landing.booking.ready')}
                                <br />
                                {t('landing.booking.create_now')}
                            </h2>
                            <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                                <a
                                    href="https://cronoscal.com/login"
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    {t('landing.booking.get_started')}
                                </a>
                                <a href="https://blog.cronoscal.com" target="_blank" className="text-sm font-semibold leading-6 text-gray-900">
                                    {t('landing.booking.learn_more')} <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    )
}

import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { eachDayOfInterval, startOfToday, startOfMonth, endOfMonth, format, isToday, isSameMonth, isEqual, parse, add, getDay } from 'date-fns';

const BookingCalendar = ({ selectedDay, availableSlot, setSelectedDay, slotRef }) => {
    const { t } = useTranslation();
    const today = startOfToday()
    const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))

    let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())
    let days = eachDayOfInterval({ start: startOfMonth(firstDayCurrentMonth), end: endOfMonth(firstDayCurrentMonth) })

    const nextMonth = () => {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }

    const previousMonth = () => {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }

    const handleSelectedDay = (day) => {
        setSelectedDay(day);
        if (window.innerWidth <= 768) { // Adjust the breakpoint as needed
            slotRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (availableSlot.length === 0) {
        return <div>Loading...</div>
    }

    const hasAvailability = (day) => {
        let formatedDay = format(day, 'yyyy-MM-dd')
        if (!availableSlot[formatedDay] || availableSlot[formatedDay].length === 0) {
            return false
        }
        return true
    }

    return (
        <div className="md:pr-14">
            <div className="flex items-center">
                <h2 className="flex-auto text-sm font-semibold text-gray-900">{format(firstDayCurrentMonth, 'MMMM yyyy')}</h2>
                <button
                    type="button"
                    onClick={previousMonth}
                    className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                    <span className="sr-only">Previous month</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    onClick={nextMonth}
                    className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                    <span className="sr-only">Next month</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
            </div>
            <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
                <div>{t('component.booking.days_initial.monday')}</div>
                <div>{t('component.booking.days_initial.tuesday')}</div>
                <div>{t('component.booking.days_initial.wednesday')}</div>
                <div>{t('component.booking.days_initial.thursday')}</div>
                <div>{t('component.booking.days_initial.friday')}</div>
                <div>{t('component.booking.days_initial.saturday')}</div>
                <div>{t('component.booking.days_initial.sunday')}</div>
            </div>
            <div className="mt-2 grid grid-cols-7 text-sm">
                {days.map((day, dayIdx) => (
                    <div key={day.toString()} className={classNames(
                        dayIdx > 6 && 'border-t border-gray-200', 'py-2',
                        dayIdx === 0 && colStartClasses[getDay(day)]
                    )}>
                        <button
                            type="button"
                            onClick={() => handleSelectedDay(day)}
                            className={classNames(
                                hasAvailability(day) && 'bg-indigo-200',
                                isEqual(day, selectedDay) && 'text-white',
                                !isEqual(day, selectedDay) && isToday(day) && 'text-indigo-600',
                                !isEqual(day, selectedDay) && !isToday(day) && isSameMonth(day, firstDayCurrentMonth) && 'text-gray-900',
                                !isEqual(day, selectedDay) && !isToday(day) && !isSameMonth(day, firstDayCurrentMonth) && 'text-gray-400',
                                isEqual(day, selectedDay) && isToday(day) && 'bg-indigo-600',
                                isEqual(day, selectedDay) && !isToday(day) && 'bg-gray-900',
                                !isEqual(day, selectedDay) && hasAvailability(day) && 'hover:bg-gray-200',
                                (isEqual(day, selectedDay) || isToday(day)) && 'font-semibold',
                                'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
                            )}
                            disabled={!hasAvailability(day)}
                        >
                            <time dateTime={format(day, 'yyy-MM-dd')}>{format(day, 'd')}</time>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default BookingCalendar

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

let colStartClasses = [
    'col-start-7',
    'col-start-1',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
]
